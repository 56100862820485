import { NETWORK } from '@arianee/arianeejs';

import { defaultCommonEnvironment } from './environment.default.common';

export const defaultDevEnvironment = {
	...defaultCommonEnvironment,
	network: NETWORK.testnet,
	production: false,
	environment: 'dev',
	arianeeshareUrl: 'http://arianeeshare.arianee.net',
	partnersLinks: 'https://arianeeshare.staging.arianee.net/partnersLinks/',
	deeplinkURL: 'https://arianeeshare.staging.arianee.net/deeplink',
	resellWatchfinder: 'https://resell.arianee.org/watchfinder',
	watchFinderAddress: '0xa2C4e69a9e4d85551ee7414301c34351738a533A',
	uwrUrl: 'https://services.arianee.com/uwr/',
	walletConnectVerified:
		'https://arianeeshare.staging.arianee.net/public/wallet-connect-verified-website.json',
	exchange: {
		url: 'https://exchange-platform-frontend-e5vjxsajla-ew.a.run.app',
	},
	blockchainProxy: {
		enable: true,
		host: 'https://api.arianee.com/report',
		base: 'https://api.arianee.com',
	},
};
