import { NETWORK } from '@arianee/arianeejs';

const WHITELABEL_HOSTNAMES_TO_CHAIN: Record<string, NETWORK> = {
	'test.arian.ee': NETWORK.testnet,
	'arian.ee': NETWORK.mainnet,
	'testnet.aria.fyi': NETWORK.testnet,
	'arialabs.arian.ee': NETWORK.mainnet,
	'poa.leclubleaderprice.fr': NETWORK.mainnet,
	'iwc-sokol.arianee.net': NETWORK.testnet,
	'poa.iwc.com': NETWORK.mainnet,
	'panerai-sokol.arianee.net': NETWORK.testnet,
	'poa.panerai.com': NETWORK.mainnet,
	'poa.yslbeauty.com': NETWORK.mainnet,
	'polygon.yslbeauty.com': NETWORK.ysl,
	'innovation-day.arian.ee': NETWORK.polygon,
	'poly.arian.ee': NETWORK.polygon,
	'stadetoulousain.arian.ee': NETWORK.stadetoulousain,
	'testsbt.arian.ee': NETWORK.testnetSbt,
	'supernet.arian.ee': NETWORK.arianeeSupernet,
	'supernet.arianee.net': NETWORK.arianeeSupernet,
	'arianeesbt.arian.ee': NETWORK.arianeesbt,
	'arianeesbt.arianee.net': NETWORK.arianeesbt,
	'sbt.panerai.com': NETWORK.arianeesbt,
	'paneraisbt.arianee.net': NETWORK.arianeesbt,
	'richemontsupernet.arianee.ee': NETWORK.richemontsupernet,
	'richemontsupernet.arianee.net': NETWORK.richemontsupernet,
	'supernettestnet.arian.ee': NETWORK.supernettestnet,
	'supernettestnet.arian.net': NETWORK.supernettestnet,
};

export const getChainFromWhitelabelHostname = (
	hostname: string,
): NETWORK | null => {
	return WHITELABEL_HOSTNAMES_TO_CHAIN[hostname.toLowerCase()] || null;
};
