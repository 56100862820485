// Temporary, see https://www.notion.so/arianee/debfdc80dfe343e994b1151ee591fbf9?v=23d2bc175eff434aa887c6bb909d1ccc&p=5a5862e5f9fa41bfaa0fb4ab4ef85419&pm=s
import { NETWORK } from '@arianee/arianeejs';

export type ChainID = number;
export enum ChainType {
	mainnet = 'mainnet',
	testnet = 'testnet',
}

export const CHAIN_TYPE_DETAILED: {
	[key in ChainType]: { name: NETWORK; id: ChainID }[];
} = {
	testnet: [
		{ name: NETWORK.testnet, id: 77 },
		{ name: NETWORK.mumbai, id: 80001 },
		{ name: NETWORK.arianeeTestnet, id: 0 },
		{ name: NETWORK.testnetSbt, id: 77 },
		{ name: NETWORK.supernettestnet, id: 999118981 },
	],
	mainnet: [
		{ name: NETWORK.mainnet, id: 99 },
		{ name: NETWORK.polygon, id: 137 },
		{ name: NETWORK.stadetoulousain, id: 137 },
		{ name: NETWORK.ysl, id: 137 },
		{ name: NETWORK.arialabs, id: 137 },
		{ name: NETWORK.arianeeSupernet, id: 11891 },
		{ name: NETWORK.arianeesbt, id: 11891 },
		{ name: NETWORK.richemontsupernet, id: 11891 },
	],
};

export interface TokenSummary {
	events: BlockchainEvent[];
	tokenId: string;
	network: NETWORK;
	owner: string;
	issuer: string;
	imprint: string;
}

export interface BlockchainEvent {
	network: NETWORK;
	smartContractName: string;
	eventName: string;
	returnValues: any;
	blockNumber: number;
	eventHash: string;
	timestamp: number;
	logBloom: any;
	eventData: any;
	logIndex: number;
	transactionHash: string;
	chainId: number;
	contractAddress: string;
}
