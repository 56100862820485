import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NETWORK } from '@arianee/arianeejs';
import {
	DisplayMapperService,
	ProductListItem,
} from '@arianeeprivate/wallet-shared-components';
import { NavController } from '@ionic/angular';
import { get } from 'lodash';
import { distinctUntilChanged, tap } from 'rxjs/operators';

import { MultichainCertificateSummary } from '../../../../../providers/arianee-blockchain-proxy-service/arianee-blockchain-proxy-service';
import { ArianeeBlockchainSyncService } from '../../../../../providers/arianee-blockchain-sync-service/arianee-blockchain-sync.service';
import { ArianeeService } from '../../../../../providers/arianee-service/arianee.service';
import { BackupCheckService } from '../../../../../providers/backup-check-service/backup-check.service';
import { EventLoggerService } from '../../../../../providers/event-logger/event-logger-service';
import { MyCertificatesService } from '../../../../../providers/my-certificates/my-certificates.service';
import { PendingNftService } from '../../../../../providers/pending-nft-service/pending-nft.service';
import { UserService } from '../../../../../providers/user-service/user.service';
import { ChainType } from '../../../../../types/multichain';

@Component({
	selector: 'app-brand-product-list-legacy',
	templateUrl: './brand-product-list-legacy.page.html',
	styleUrls: ['./brand-product-list-legacy.page.scss'],
	providers: [BackupCheckService],
})
export class BrandProductListLegacyPage implements OnInit, OnDestroy {
	brandId: string;
	brandsCount = 0;
	brandName: string;
	brandLogo: string;
	isLoading = true;
	subProducts: Array<ProductListItem> = [undefined, undefined];
	productList: MultichainCertificateSummary[];

	private chainType: ChainType;
	private subscriptions = [];

	constructor(
		private navCtrl: NavController,
		private route: ActivatedRoute,
		private eventLogger: EventLoggerService,
		public pendingNftService: PendingNftService,
		private myCertificatesService: MyCertificatesService,
		private displayMapperService: DisplayMapperService,
		private arianeeBkchainSync: ArianeeBlockchainSyncService,
		private backupCheckService: BackupCheckService,
		private userService: UserService,
		private arianeeService: ArianeeService,
	) {}

	ngOnInit() {
		this.setChainType();
		this.brandId = this.route.snapshot.paramMap.get('brandId');

		this.myCertificatesService
			.getAllMyCertificatesIssuedBy(
				this.brandId,
				{
					content: true,
					events: true,
				},
				0,
				NETWORK.mainnet,
			)
			.pipe(
				tap(({ certificates }) => {
					this.brandsCount = (certificates || []).length;
				}),
			)
			.subscribe(({ certificates: productList }) => {
				this.isLoading = false;
				if (!productList || productList.length === 0) {
					this.navCtrl.navigateBack('/tab/brand-list');
				} else {
					this.productList = productList;
					this.brandName = get(
						productList[0],
						'issuer.identity.data.name',
						'Unknown',
					);
					this.subProducts = productList.map((certificate) => {
						try {
							return this.displayMapperService.productListDisplayMapper(
								certificate,
							);
						} catch {
							return undefined;
						}
					});
					this.brandLogo = this.subProducts[0].brandLogo;
					this.brandName = this.subProducts[0].brandName;
				}
				this.arianeeBkchainSync.syncWithBlockchain();
			});
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ionViewDidEnter() {
		this.eventLogger.logScreen('brand-product-list', { brandId: this.brandId });
	}

	onBrandClick() {
		this.navCtrl.navigateForward(`/tab/brand-page/${this.brandId}`);
	}

	onClickProduct(productId) {
		if (this.pendingNftService.isPendingNft(productId, this.chainType)) {
			const { network } = this.pendingNftService.getPendingNft(
				this.chainType,
				productId,
			);
			this.navCtrl.navigateForward(
				`/tab/brand-list/pending/${network}/${productId}`,
			);
		} else {
			const certificate = this.productList.find(
				(certificate) => certificate.certificateId === productId,
			);
			if (!certificate) return;

			const network = certificate.network;
			this.navCtrl.navigateForward(
				`/tab/brand-list/product-detail/${network}/${productId}`,
			);
		}
	}

	onClickNavBack() {
		this.navCtrl.navigateBack('/tab/brand-list');
	}

	private setChainType() {
		const chainTypeSub = this.userService.$chainType
			.get()
			.pipe(distinctUntilChanged())
			.subscribe((chainType) => {
				this.chainType = chainType;
			});

		this.subscriptions.push(chainTypeSub);
	}
}
