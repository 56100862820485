import { Injectable } from '@angular/core';
import { NETWORK } from '@arianee/arianeejs/dist/src';
import { CertificateEvents } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary/certificateSummary';
import { ethers } from 'ethers';
import { get, uniq } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, from } from 'rxjs';
import {
	map,
	mergeMap,
	shareReplay,
	startWith,
	switchMap,
	take,
} from 'rxjs/operators';

import { findMostRecentTransferEventTo } from '../../helpers/events/events';
import { ChainType } from '../../types/multichain';
import {
	ArianeeBlockchainProxyService,
	MultichainCertificateSummary,
} from '../arianee-blockchain-proxy-service/arianee-blockchain-proxy-service';
import { ArianeeEventWatcherService } from '../arianee-event-watcher.service.ts/arianee-event-watcher.service';
import { ArianeeService } from '../arianee-service/arianee.service';
import { PendingNftService } from '../pending-nft-service/pending-nft.service';
import { UserService } from '../user-service/user.service';

@Injectable({
	providedIn: 'root',
})
export class MyCertificatesService {
	chainType: ChainType;
	private cachedCertificatesByIssuer = new BehaviorSubject<
		Record<
			string,
			Observable<{
				certificates: MultichainCertificateSummary[];
				totalIssuers: number;
				totalCertificate: number;
			}>
		>
	>({});

	constructor(
		private pendingNftService: PendingNftService,
		private arianeeService: ArianeeService,
		private arianeeBlockchainProxyService: ArianeeBlockchainProxyService,
		private userService: UserService,
		private arianeeEventWatcherService: ArianeeEventWatcherService,
	) {}

	/**
	 * Get all the current user certificates (pending + owned) grouped by issuer
	 * @returns arrays of CertificateSummary grouped by issuer
	 */
	public getAllMyCertificatesGroupedByIssuer(): Observable<{
		[issuer: string]: MultichainCertificateSummary[];
	}> {
		const _$transferToReceived =
			this.arianeeEventWatcherService.$transferToReceived.pipe(startWith([]));

		return combineLatest([
			this.arianeeService.$address,
			_$transferToReceived,
		]).pipe(
			mergeMap(async ([address]) => {
				this.chainType = this.userService.$chainType.getValue();
				const certificatesGroupedByIssuer =
					await this.arianeeBlockchainProxyService.getCertificatesGroupedByIssuer(
						this.chainType,
						address,
					);
				return this.mergeGroupedCertificatesWithPending(
					this.chainType,
					certificatesGroupedByIssuer,
				);
			}),
		);
	}

	private mergeGroupedCertificatesWithPending(
		chainType: ChainType,
		certificatesGroupedByIssuer: {
			[issuer: string]: MultichainCertificateSummary[];
		},
	): { [issuer: string]: MultichainCertificateSummary[] } {
		const flattenedCertificates = [].concat(
			...Object.values(certificatesGroupedByIssuer || {}),
		);
		const pendingNftsGroupedByIssuer =
			this.pendingNftService.getPendingNftsGroupedByBrand(
				chainType,
				flattenedCertificates,
			);

		const issuers = uniq(
			Object.keys(certificatesGroupedByIssuer || {}).concat(
				Object.keys(pendingNftsGroupedByIssuer || {}),
			),
		);

		const merged = {};

		issuers.forEach((issuer) => {
			merged[issuer] = get(
				certificatesGroupedByIssuer,
				`[${issuer}]`,
				[],
			).concat(
				get(
					pendingNftsGroupedByIssuer,
					`[${issuer}]`,
					[],
				) as MultichainCertificateSummary[],
			);
		});

		return merged;
	}

	/**
	 * Get all the current user certificates (pending + owned) issued by address passed in parameter
	 * @param issuer the address that issued the certificates
	 * @param augmentWith whether the content and/or events of the certificate
	 * should be included in the returned certificates
	 * @param chainType
	 * @param network
	 * @returns array of CertificateSummary issued by `issuer`
	 */
	public getAllMyCertificatesIssuedBy(
		issuer: string,
		augmentWith: {
			content: boolean;
			events: boolean;
		} = { content: true, events: false },
		limit = 0,
		network?: NETWORK,
	): Observable<{
		certificates: MultichainCertificateSummary[];
		totalIssuers: number;
		totalCertificate: number;
	}> {
		const { content, events } = augmentWith;
		issuer = ethers.utils.getAddress(issuer);

		return combineLatest([
			this.getAllMyCertificatesGroupedByIssuer(),
			from(this.arianeeService.getWalletInstance(network)),
		]).pipe(
			mergeMap(async ([certificatesGroupedByIssuer, wallet]) => {
				const totalCertificate = certificatesGroupedByIssuer[issuer].length;
				const certificatesOfIssuer = MyCertificatesService.sortByClaimDate(
					certificatesGroupedByIssuer[issuer],
					wallet.address,
					'desc',
				);

				if (content) {
					const setContentPromises: Promise<void>[] = [];
					if (limit > 0)
						certificatesOfIssuer.splice(
							limit,
							certificatesOfIssuer.length - limit,
						);

					for (const mergedCertificate of certificatesOfIssuer) {
						if (
							this.pendingNftService.isPendingNft(
								mergedCertificate.certificateId,
								this.chainType,
							)
						)
							continue;

						setContentPromises.push(
							new Promise((resolve, reject) => {
								this.getCertificateContent(mergedCertificate)
									.then((content) => {
										mergedCertificate.content = content;
										resolve();
									})
									.catch((err) => reject(err));
							}),
						);
					}

					await Promise.all(setContentPromises);
				}

				return {
					certificates: certificatesOfIssuer,
					totalIssuers: Object.keys(certificatesGroupedByIssuer).length,
					totalCertificate,
				};
			}),
		);
	}

	/**
	 * Get the content of the certificate passed in parameter, if the certificate already has a content,
	 * returns the certificate's content.
	 * @param certificate the certificate to get content from
	 * @returns a multichain certificate summary's content
	 */
	private async getCertificateContent(
		certificate: MultichainCertificateSummary,
	): Promise<MultichainCertificateSummary['content']> {
		if (certificate.content) return certificate.content;

		const { network } = certificate;
		const wallet = await this.arianeeService.getWalletInstance(network);
		const { content } = await wallet.methods.getCertificate(
			certificate.certificateId,
			undefined,
			{ content: true },
		);
		return content;
	}

	/**
	 * Returns a new array of certificates sorted by claim date in the order passed
	 * in parameter. If the method is unable to retrieve the claim date, the order will be the
	 * same as in the array passed in parameters.
	 * @param certificates certificates to sort by claim date
	 * @param userAddress
	 * @param order order to be used, either ascending or descending
	 * @returns a new array of certificates sorted by claim
	 * */
	static sortByClaimDate(
		certificates: MultichainCertificateSummary[],
		userAddress: string,
		order: 'asc' | 'desc',
	): MultichainCertificateSummary[] {
		const tokenIdToClaimDate = new Map<string, number>();

		for (const certificate of certificates) {
			const events = get(certificate, 'events', {}) as CertificateEvents;
			const mostRecentTransferTo = findMostRecentTransferEventTo(
				events,
				userAddress,
			);
			const claimDate = mostRecentTransferTo
				? mostRecentTransferTo.timestamp
				: 0;
			tokenIdToClaimDate.set(certificate.certificateId, claimDate);
		}

		return certificates
			.map((elm) => elm)
			.sort((a, b) =>
				order === 'desc'
					? tokenIdToClaimDate.get(b.certificateId) -
					  tokenIdToClaimDate.get(a.certificateId)
					: tokenIdToClaimDate.get(a.certificateId) -
					  tokenIdToClaimDate.get(b.certificateId),
			);
	}

	public getCertificatesByIssuer(
		issuerAddress: string,
		maxCertificateToGet = 0,
		forceRefresh = false,
	): Observable<{
		certificates: MultichainCertificateSummary[];
		totalIssuers: number;
		totalCertificate: number;
	}> {
		return this.cachedCertificatesByIssuer.pipe(
			take(1),
			switchMap((cache) => {
				if (!cache[issuerAddress] || forceRefresh) {
					cache[issuerAddress] = this.retrieveCertificates(
						issuerAddress,
						maxCertificateToGet,
					).pipe(shareReplay(1));
					this.cachedCertificatesByIssuer.next(cache);
				}
				return cache[issuerAddress];
			}),
		);
	}

	private retrieveCertificates(
		issuerAddress: string,
		maxCertificateToGet = 0,
	): Observable<{
		certificates: MultichainCertificateSummary[];
		totalIssuers: number;
		totalCertificate: number;
	}> {
		return this.getAllMyCertificatesIssuedBy(
			issuerAddress,
			{
				content: true,
				events: true,
			},
			maxCertificateToGet,
		).pipe(
			map(({ certificates, totalIssuers, totalCertificate }) => ({
				certificates,
				totalIssuers,
				totalCertificate,
			})),
		);
	}

	public getHasNft(): Observable<boolean> {
		const certificatesGroupedByIssuer$ =
			this.getAllMyCertificatesGroupedByIssuer().pipe(
				map((certificatesGroupedByIssuer) => {
					const issuers = Object.keys(certificatesGroupedByIssuer || {});
					return issuers.map((issuerName) => {
						return certificatesGroupedByIssuer[issuerName] || [];
					});
				}),
			);

		return certificatesGroupedByIssuer$.pipe(
			map(
				(certificatesGroupedByIssuer) => certificatesGroupedByIssuer.length > 0,
			),
		);
	}
}
